

import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Stack, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { Input as BaseInput } from '@mui/base/Input';
import { Box, styled } from '@mui/system';
import { base_Url } from '../BaseUrl/ApiUrl';
import axios from 'axios';
import { Toaster, toast } from "sonner";
import oneactlogo from '../../assets/1actLogo.png'
import { FaCaretRight } from "react-icons/fa";
import NewUserRegister from '../Register/NewUserRegister';
import { googleAnalyticsFunction } from '../googleanalytics/googleanalytics';







function OTP({ separator, length, value, onChange }) {
    const inputRefs = React.useRef(new Array(length).fill(null));

    const focusInput = (targetIndex) => {
        const targetInput = inputRefs.current[targetIndex];
        targetInput.focus();
    };

    const selectInput = (targetIndex) => {
        const targetInput = inputRefs.current[targetIndex];
        targetInput.select();
    };

    const handleKeyDown = (event, currentIndex) => {
        switch (event.key) {
            case 'ArrowUp':
            case 'ArrowDown':
            case ' ':
                event.preventDefault();
                break;
            case 'ArrowLeft':
                event.preventDefault();
                if (currentIndex > 0) {
                    focusInput(currentIndex - 1);
                    selectInput(currentIndex - 1);
                }
                break;
            case 'ArrowRight':
                event.preventDefault();
                if (currentIndex < length - 1) {
                    focusInput(currentIndex + 1);
                    selectInput(currentIndex + 1);
                }
                break;
            case 'Delete':
                event.preventDefault();
                onChange((prevOtp) => {
                    const otp =
                        prevOtp.slice(0, currentIndex) + prevOtp.slice(currentIndex + 1);
                    return otp;
                });

                break;
            case 'Backspace':
                event.preventDefault();
                if (currentIndex > 0) {
                    focusInput(currentIndex - 1);
                    selectInput(currentIndex - 1);
                }

                onChange((prevOtp) => {
                    const otp =
                        prevOtp.slice(0, currentIndex) + prevOtp.slice(currentIndex + 1);
                    return otp;
                });
                break;

            default:
                break;
        }
    };

    const handleChange = (event, currentIndex) => {
        const currentValue = event.target.value;
        let indexToEnter = 0;

        while (indexToEnter <= currentIndex) {
            if (inputRefs.current[indexToEnter].value && indexToEnter < currentIndex) {
                indexToEnter += 1;
            } else {
                break;
            }
        }
        onChange((prev) => {
            const otpArray = prev.split('');
            const lastValue = currentValue[currentValue.length - 1];
            otpArray[indexToEnter] = lastValue;
            return otpArray.join('');
        });
        if (currentValue !== '') {
            if (currentIndex < length - 1) {
                focusInput(currentIndex + 1);
            }
        }
    };

    const handleClick = (event, currentIndex) => {
        selectInput(currentIndex);
    };

    const handlePaste = (event, currentIndex) => {
        event.preventDefault();
        const clipboardData = event.clipboardData;

        // Check if there is text data in the clipboard
        if (clipboardData.types.includes('text/plain')) {
            let pastedText = clipboardData.getData('text/plain');
            pastedText = pastedText.substring(0, length).trim();
            let indexToEnter = 0;

            while (indexToEnter <= currentIndex) {
                if (inputRefs.current[indexToEnter].value && indexToEnter < currentIndex) {
                    indexToEnter += 1;
                } else {
                    break;
                }
            }

            const otpArray = value.split('');

            for (let i = indexToEnter; i < length; i += 1) {
                const lastValue = pastedText[i - indexToEnter] ?? ' ';
                otpArray[i] = lastValue;
            }

            onChange(otpArray.join(''));
        }
    };

    return (
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            {new Array(length).fill(null).map((_, index) => (
                <React.Fragment key={index}>
                    <BaseInput
                        slots={{
                            input: InputElement,
                        }}
                        aria-label={`Digit ${index + 1} of OTP`}
                        slotProps={{
                            input: {
                                ref: (ele) => {
                                    inputRefs.current[index] = ele;
                                },
                                onKeyDown: (event) => handleKeyDown(event, index),
                                onChange: (event) => handleChange(event, index),
                                onClick: (event) => handleClick(event, index),
                                onPaste: (event) => handlePaste(event, index),
                                value: value[index] ?? '',
                            },
                        }}
                    />
                    {index === length - 1 ? null : separator}
                </React.Fragment>
            ))}
        </Box>
    );
}

// OTP.propTypes = {
//     length: PropTypes.number.isRequired,
//     onChange: PropTypes.func.isRequired,
//     separator: PropTypes.node,
//     value: PropTypes.string.isRequired,
// };


export function OTPInput({ setOtpStage, setExistOtpOpen, formData, packageId ,setOpenRegister,openRegister,setFinalData,finalData}) {

    const failure = (data) => {
        toast.error(data, {
            position: 'top-center',
            style: { border: '1px solid red' },
            duration: 3000,
            cancel: {
                label: "x",
                // style: {
                //     backgroundColor: '#a8261d', 
                //     color: '#ffffff', 
                //     border: 'none', 
                //     padding: '2px 8px',
                //     borderRadius: '4px', 
                // },
                onClick: () => console.log('Cancel!'),
            },

        });
    }

    const [otp, setOtp] = useState('');

    const [timer, setTimer] = useState(30);
    const [trigger, setTrigger] = useState(false);

    useEffect(() => {
        if (timer === 0) return; // Prevent starting a countdown when timer is already 0
        const countdown = setInterval(() => {
            setTimer((prev) => {
                if (prev > 0) {
                    return prev - 1;
                } else {
                    clearInterval(countdown);
                    return 0;
                }
            });
        }, 1000);

        return () => clearInterval(countdown); // Cleanup on component unmount
    }, [trigger, timer]);






    const fetchcheckPackageisExist = async (studentidd) => {


        try {

            const response = await axios.post(`${base_Url}/checkPackageisExist`, {
                packageid: packageId,
                studentid: studentidd || 0
            })
            if (response.data.status) {
                console.log('response in my custom fun', response?.data?.response)


                const dataForGTM = response?.data?.response

                const courseData = dataForGTM?.packages?.map((val) => {
                    return {
                        productName: val?.packagename,
                        productPrice: val?.packageamount
                    }
                })


                googleAnalyticsFunction(dataForGTM, courseData)
                setFinalData(response?.data?.response)
                setOpenRegister(true)




                // login popup sstate
                setExistOtpOpen(false)



            } else {
                failure(response.data?.message)
                setFinalData({})
            }
        } catch (error) {
            console.log(error)
        }
    }





    const handleSubmitOtp = async (formData) => {
        try {
            const res = await axios.post(`${base_Url}loginwithotp`, {
                email: formData?.email,
                otp: `${otp}`,
                roleid: 2
            })

            console.log(`epic response otp - `, res);


            if (res?.data?.status == false) {
                failure(`invalid OTP`)
            }
            else {
                toast.success(`OTP Verified`, {
                    position: 'top-center',
                    style: { border: '1px solid green' },
                    duration: 3000,
                    cancel: {
                        label: "x",
                        // style: {
                        //     backgroundColor: '#a8261d', 
                        //     color: '#ffffff', 
                        //     border: 'none', 
                        //     padding: '2px 8px',
                        //     borderRadius: '4px', 
                        // },
                        onClick: () => console.log('Cancel!'),
                    },

                });
                // setFormData({
                //     ...formData,
                //     ['firstname']: res?.data?.firstname,
                //     ['lastname']: res?.data?.lastname,
                //     ['phonenumber']: res?.data?.contactno,
                //     ['billingaddress']: res?.data?.address,
                //     ['zipcode']: 'Georgia',
                //     ['Dob']: 'Georgia',
                //     ['newcountry']: 'Georgia',
                //     ['newstate']: 'Georgia',
                //     ['billingcity']: 'Georgia',
                //     ['email']: 'Georgia',
                // });

                localStorage.setItem('Student', JSON.stringify(res?.data?.response))
                window.dispatchEvent(new Event("storage"));
                await fetchcheckPackageisExist(res?.data?.response?.customerid)


                // setOtpStage(1)
            }
        } catch (error) {
            console.log(`error in handleSendOtp - `, error);
        }
    }

    const handleResend = async () => {
        setTimer(30); // Reset timer back to 30 seconds
        setTrigger(!trigger); // Toggle trigger to restart the countdown

        try {
            const res = await axios.post(`${base_Url}sendotptomail`, {
                email: formData?.email,
                roleid: 2
            })

            if (res?.data?.status == false) {
                failure(res?.data?.message)
            }
            else {
                toast.success(`Please Check Your Mail For OTP`, {
                    position: 'top-center',
                    style: { border: '1px solid green' },
                    duration: 3000,
                    cancel: {
                        label: "x",
                        // style: {
                        //     backgroundColor: '#a8261d', 
                        //     color: '#ffffff', 
                        //     border: 'none', 
                        //     padding: '2px 8px',
                        //     borderRadius: '4px', 
                        // },
                        onClick: () => console.log('Cancel!'),
                    },

                });
            }
        } catch (error) {
            console.log(`error in handleSendOtp - `, error);
        }
    };


    console.log('openRegister status', openRegister)
    console.log('final data ###@@@!!!', finalData)
    return (
        <>
    
            <Typography variant='h5' sx={{ color: 'black !important', marginTop: '40px' }} >Verify OTP</Typography>

            <Typography variant='body1'>Please enter the OTP sent to {formData?.email}</Typography>

            <Stack sx={{ margin: '20px 0' }} justifyContent="left" spacing={1} alignItems="center">
                <Box sx={{ margin: '10px 0 !important' }}>
                    <OTP value={otp} onChange={setOtp} length={6} />
                </Box>
                <Button variant="contained" onClick={() => handleSubmitOtp(formData)}>Submit</Button>
                {
                    timer != 0 && <Typography variant="caption">Resend OTP in {timer}s</Typography>
                }
                {
                    timer == 0 && <Typography variant="body2">Didn't receive a code?</Typography>
                }
                <Button color={'warning'} disabled={timer !== 0} onClick={handleResend}>
                    {timer === 0 ? 'Resend' : 'Resend'}<FaCaretRight />
                </Button>
                {
                    timer == 0 && <Typography variant="body2">Please click on "Resend" to receive OTP on your Email</Typography>
                }
            </Stack>





        </>
    );
}


const blue = {
    100: '#DAECFF',
    200: '#80BFFF',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    700: '#0059B2',
};

const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
};

const InputElement = styled('input')(
    ({ theme }) => `
    width: 40px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 0px;
    border-radius: 8px;
    text-align: center;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0px 2px 4px ${theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.5)' : 'rgba(0,0,0, 0.05)'
        };
  
    &:hover {
      border-color: ${blue[400]};
    }
  
    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
    }
  
    // firefox
    &:focus-visible {
      outline: 0;
    }
  `,
);




const OTPBasedLogin = ({ existOtpOpen, setExistOtpOpen, packageId }) => {


    const [formData, setFormData] = useState({})
    const handleChange = (e) => {
        setFormData({ ...formData, email: e.target.value });
    };
    const failure = (data) => {
        toast.error(data, {
            position: 'top-center',
            style: { border: '1px solid red' },
            duration: 3000,
            cancel: {
                label: "x",
                onClick: () => console.log('Cancel!'),
            },

        });
    }

    const handleClickOpen = () => {
        setExistOtpOpen(true);
    };

    const handleClose = () => {
        setExistOtpOpen(false);
    };

    const [otpStage, setOtpStage] = useState(1)

    useEffect(() => {
        setOtpStage(1)
    }, [])

    const handleSendOtp = async () => {
        try {
            const res = await axios.post(`${base_Url}sendotptomail`, {
                email: formData?.email,
                roleid: 2
            })

            if (res?.data?.status == false) {
                failure(res?.data?.message)
            }
            else {
                toast.success(`Please Check Your Mail For OTP`, {
                    position: 'top-center',
                    style: { border: '1px solid green' },
                    duration: 3000,
                    cancel: {
                        label: "x",
                        onClick: () => console.log('Cancel!'),
                    },

                });
                setOtpStage(2)
            }
        } catch (error) {
            console.log(`error in handleSendOtp - `, error);
        }
    }






    const [finalData, setFinalData] = useState({})
    const [openRegister, setOpenRegister] = useState(false)
    return (
        <>
            <Toaster />
            <Dialog
                open={existOtpOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth="sm"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">


                        {
                            otpStage == 1 ? <Stack sx={{ margin: '20px 0' }} justifyContent={'start'} spacing={1} >
                                <Stack direction={'row'} justifyContent={'center'}>
                                    <img src={oneactlogo} width={'170px'} height={'100px'} alt="one-act logo" />
                                </Stack>

                                <Typography variant='h5' sx={{ color: 'black !important' }}>Welcome back!</Typography>
                                <Typography variant='body1'>Seems like you are a Returning user!</Typography>


                                <Typography variant='h5' sx={{ color: 'black !important', marginTop: '40px !important' }}>Log-in Using OTP</Typography>
                                <Typography>Email Id</Typography>
                                <TextField
                                    label="Email"
                                    variant="outlined"
                                    value={formData.email}
                                    onChange={handleChange}
                                    sx={{ marginBottom: 2 }}
                                />
                                <Stack direction={'row'} justifyContent={'center'}>
                                    <Button variant='contained' onClick={handleSendOtp}>Request OTP</Button>
                                </Stack>
                            </Stack> : <OTPInput setExistOtpOpen={setExistOtpOpen} setOtpStage={setOtpStage} formData={formData}
                                packageId={packageId}

                                setOpenRegister={setOpenRegister}
                                openRegister={openRegister}
                                setFinalData={setFinalData}
                                finalData={finalData}

                            />
                        }



                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                </DialogActions>
            </Dialog>






            <NewUserRegister
                openStatus={openRegister}
                setRegisterStatus={setOpenRegister}
                finalData={finalData}
                cus={123}
            />
        </>
    )
}

export default OTPBasedLogin;