import React, { useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import { CardMedia, Grid, Stack, Box, Button, Popover, Divider, Avatar, useMediaQuery } from '@mui/material';
import { BrowserRouter as Link, useNavigate } from 'react-router-dom';
import { FaRegUserCircle } from "react-icons/fa";
import logo from "../../assets/images/home/logo.png"
import { AiOutlineDashboard } from "react-icons/ai";
import { IoIosLogOut } from "react-icons/io";
import Badge from '@mui/material/Badge';
import { BsCart3 } from "react-icons/bs";
import DehazeIcon from '@mui/icons-material/Dehaze';
import CloseIcon from '@mui/icons-material/Close';
import { CiWallet } from "react-icons/ci";

import { useMenu, MenuProvider } from '@mui/base/useMenu';
import { useMenuItem } from '@mui/base/useMenuItem';
import { Popper } from '@mui/base/Popper';
import { useDropdown, DropdownContext } from '@mui/base/useDropdown';
import { useMenuButton } from '@mui/base/useMenuButton';
import { style, useTheme } from '@mui/system';
import clsx from 'clsx';
import _ from 'lodash'
import user from '../../assets/user.jpg';
import { IoIosArrowDown } from "react-icons/io";

import CryptoJS from 'crypto-js';

/*******mobilemenu-offcanvas********/

import { useState } from 'react';
import { Drawer } from '@mui/material';

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddtoCart from './AddtoCart';

import ExistingLogin from './ExistingLogin';
import axios from 'axios';
import { base_Url } from '../../pages/BaseUrl/ApiUrl';
import { Margin } from '@mui/icons-material';

/*******mobilemenu-offcanvas********/


const styles = {

  DesktopMenu: {
    '@media (max-width: 1300px)': {
      display: 'none',
    },
  },

  MobileMenu: {
    display: 'none',
    '@media (max-width: 1000px)': {
      display: 'block',
    },
  },

  headerTop: {
    display: 'flex', justifyContent: 'space-between', padding: '5px 80px',

    '@media (max-width: 1600px)': {
      padding: '5px 10px'
    },
  },

  menuList: {
    display: 'flex', gap: '30px', padding: '0px !important',
    '@media (max-width: 1400px)': {
      gap: '16px',
    },
    '@media (min-width : 1401px) and (max-width : 1515px)': {
      gap: '10px'
    }

  },

  menuListPadding: {
    width: '100%',
    display: 'flex',
    padding: '11px 0px 12px 60px', // Default for larger screens
    '@media (max-width: 1600px)': {
      padding: '11px 0px 12px 30px', // Adjusted for medium-large screens
    },
    '@media (max-width: 1200px)': {
      padding: '10px 0px 10px 20px', // Slightly smaller padding for medium screens
    },
    '@media (max-width: 900px)': {
      padding: '8px 0px 8px 15px', // Adjusted for small screens
    },
    '@media (max-width: 600px)': {
      padding: '5px 0px 5px 10px', // Tighter padding for extra small screens
    },
    '@media (max-width: 400px)': {
      padding: '3px 0px 3px 8px', // Minimal padding for the smallest screens
    },
  },

  headerTopText: {
    fontSize: '12px !important', color: '#fff !important',
    '@media (max-width: 1000px)': {
      fontSize: '10px !important',
    },
  },

  mobileCanvas: {
    display: 'none',
    '@media (max-width: 1000px)': {
      display: 'block',
    }
  },

  tabletView: {
    display: 'none',
    '@media (max-width: 1299px) and (min-width: 1001px)': {
      display: 'block'
    }
  },


  mobileCanvasOuter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px'
  },

  accordion: {
    boxShadow: 'unset',
    "&::before": {
      display: 'none',
    },
  },

  AccordionSummary: {
    padding: '0px !important'
  },

  loginButton: {
    background: "var--(blue)",
    color: "#fff",
    border: "1px solid var(--blue)",
    fontSize: "13px",
    fontWeight: "500",
    padding: '5px 20px',
    "&:hover": {
      background: "",
      color: "",
      border: "1px solid var(--blue)",
    },
    // MarginLeft:'20px'

  }



}



const Menu = React.forwardRef(function Menu(props, ref) {

  const { children, ...other } = props;

  const { open, triggerElement, contextValue, getListboxProps } = useMenu({
    listboxRef: ref,
  });

  return (
    <Popper open={open} anchorEl={triggerElement}>
      <ul className="menu-root" {...other} {...getListboxProps()}>
        <MenuProvider value={contextValue}>{children}</MenuProvider>
      </ul>
    </Popper>
  );
});

const MenuItem = React.forwardRef(function MenuItem(props, ref) {
  const { children, onClick, ...other } = props;

  const { getRootProps, disabled, focusVisible } = useMenuItem({ rootRef: ref });

  const classes = {
    'focus-visible': focusVisible,
    'menu-item': true,
    disabled,
  };

  return (
    <li
      {...other}
      {...getRootProps({ onClick: onClick ?? (() => { }) })}
      className={clsx(classes)}
    >
      {children}
    </li>
  );
});

const MenuButton = React.forwardRef(function MenuButton(props, forwardedRef) {
  const { getRootProps: getButtonProps } = useMenuButton({ rootRef: forwardedRef });

  return (
    <button type="button" {...props} {...getButtonProps()} className="button" />
  );
});



const Header = () => {


  {/********MObileMenu-offcanvas*****/ }
  const [open, setOpen] = useState(false);

  const toggleDrawer = (isOpen) => () => {
    setOpen(isOpen);
  };
  {/********MObileMenu-offcanvas*****/ }


  const { contextValue: dropdownContextValue1 } = useDropdown();

  const createHandleMenuClick1 = (menuItem1) => {
    return () => {
      console.log(`Clicked on ${menuItem1}`);
    };
  };

  const { contextValue: dropdownContextValue2 } = useDropdown();



  const createHandleMenuClick2 = (menuItem2) => {
    return () => {
      console.log(`Clicked on ${menuItem2}`);
    };
  };

  const { contextValue: dropdownContextValue3 } = useDropdown();
  const createHandleMenuClick3 = (menuItem3) => {
    return () => {
      console.log(`Clicked on ${menuItem3}`);
    };
  };


  const { contextValue: dropdownContextValue4 } = useDropdown();
  const { contextValue: dropdownContextValue5 } = useDropdown();
  const { contextValue: dropdownContextValue6 } = useDropdown();



  const navigate = useNavigate()




  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    console.log('click')
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openState = Boolean(anchorEl);
  const id = openState ? 'simple-popover' : undefined;



  // const handleLogout = () => {
  //   localStorage.removeItem('Student')
  //   setAnchorEl(null);

  // }


  const handleLogout = async () => {

    const paramData = {
      userid: userid,
      roleid: 2, // 1=>admin,2=>student,3=>Instructor,4=>parent,5-Employee
      config: 1 // 1 - web, 2- app
    }

    await axios.post(`${base_Url}removefcmtoken`, paramData).then((res) => {
      console.log(`fcm removed successfullly`);
      if (res.data.status == true) {

        localStorage.removeItem('Student')
        window.dispatchEvent(new Event("storage"));
        setAnchorEl(null);
        navigate('/')
      }

    })
      .catch((error) => {
        console.log(`error in reomveFcmToken - `, error);
      })

  };




  // const studentDetails = JSON.parse(localStorage.getItem('Student'))
  // const userid = studentDetails?.customerid




  const [studentDetails, setStudentDetails] = useState(() => {
    return JSON.parse(localStorage.getItem("Student")) || {};
  });

  useEffect(() => {
    const handleStorageChange = () => {
      setStudentDetails(JSON.parse(localStorage.getItem("Student")) || {});
    };

    // Listen for localStorage updates (for cross-tab updates)
    window.addEventListener("storage", handleStorageChange);



    return () => {
      window.removeEventListener("storage", handleStorageChange);

    };
  }, []);

  const userid = studentDetails?.customerid || null;






  const [openStatus, setRegisterStatus] = useState(false)

  const isMediumWidth = useMediaQuery(`(min-width:1400px) and (max-width:1515px)`);


  // mui response
  const theme = useTheme();

  const isSmallRange = useMediaQuery('(min-width:900px) and (max-width:951px)');
  // mui response

  // password encryption 






  const SECRET_KEY = CryptoJS.enc.Utf8.parse("12345678901234567890123456789012"); // 32-byte key
  const IV = CryptoJS.enc.Utf8.parse("1234567890123456"); // 16-byte IV

  const encryptData = (data) => {
    const cipher = CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY, {
      iv: IV,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    return cipher.toString(); // Base64-encoded encrypted string
  };

  const handleGoToDashboard = () => {

    console.log('>>>>>', window.location.origin)

    const domain = () => {
      switch (window.location.origin) {
        case 'http://localhost:3000':
          return "http://localhost:3001";
        case 'http://localhost:3001':
          return "http://localhost:3000";
        case 'https://projectsideaboxdigital.io.in':
          return "https://user.projectsideaboxdigital.io.in";
        case 'https://www.1statlantaduischool.com':
          return "https://www.student.1statlantaduischool.com";
        default:
          return window.location.origin;
      }
    }

    const data = { email: studentDetails?.email, password: studentDetails?.password }; // Data to encrypt
    const encryptedData = encryptData(data);

    console.log("Encrypted Data:", encryptedData);

    // Ensure encrypted data is URL-safe
    const encodedData = encodeURIComponent(encryptedData);

    // Open the destination URL in a new tab with the encrypted data as a query parameter
    window.open(`${domain()}/?data=${encodedData}`, '_blank');
  };



  return (
    <>

      {/* desktop */}
      <Box display='flex' sx={styles.DesktopMenu} >

        <CssBaseline />

        <AppBar component="nav" sx={styles.DesktopMenu} >

          <Grid container spacing={2} display='flex' gap='0px'>
            <Grid item md={2}>
              <Typography onClick={() => navigate('/')}>
                <Grid className='logo_box' >
                  <CardMedia
                    className="logo_shape"
                    component="img"
                    alt="logo"
                    src={logo}
                    sx={{ width: '140px' }}
                  />
                </Grid>
              </Typography>
            </Grid>

            <Grid item md={10} sx={{ margin: '0px !important', padding: '16px 0px 0px !important' }}>
              <Grid className='head_top_1'
              // sx={{ background: 'var(--primary)', }}
              >

                <Grid sx={styles.headerTop}>
                  <Grid sx={{ display: 'flex', alignItems: 'center', }}>
                    <Typography sx={styles.headerTopText}>Mon-Fri (8am-8pm) / Sat & Sun (8am-6pm)</Typography>
                    {/* <Typography sx={styles.headerTopText}>Mon-Fri (8am-8pm) </Typography>
                    <Typography sx={styles.headerTopText}>Sat & Sun (8am-6pm)</Typography> */}
                  </Grid>
                  <Grid sx={{ display: 'flex', gap: '20px', alignItems: 'center', }}>
                    {/* <Typography sx={styles.headerTopText}>info@1actdrivingschools.com</Typography>
                    <Typography sx={{ fontSize: '12px !important', color: '#fff !important', fontWeight: '600 !important' ,cursor:'pointer'}} className='navbar_cal_but'>Call 770-274-4223  {' '} |   Text 404-590-4570</Typography> */}

                    {/* <Typography sx={styles.headerTopText}>info@1actdrivingschools.com</Typography>

                    <Box sx={{ display: 'flex' }}>
                      <Typography sx={{ fontSize: '12px !important', color: '#fff !important', fontWeight: '600 !important', cursor: 'pointer' }} className='navbar_cal_but'>Call 770-274-4223  </Typography>

                      <Typography sx={{ fontSize: '12px !important', color: '#fff !important', fontWeight: '600 !important', cursor: 'pointer' }} className='navbar_cal_but2'> Text 404-590-4570</Typography>
                    </Box> */}


                    <Typography
                      sx={styles.headerTopText}
                      onClick={() => (window.location.href = 'mailto:info@1actdrivingschools.com')}
                      style={{ cursor: 'pointer' }}
                    >
                      info@1actdrivingschools.com
                    </Typography>

                    <Box sx={{ display: 'flex' }}>
                      <Typography
                        sx={{ fontSize: '12px !important', color: '#fff !important', fontWeight: '600 !important', cursor: 'pointer' }}
                        className='navbar_cal_but2'
                        onClick={() => (window.location.href = 'tel:770-274-4223')}
                      >
                        Call 770-274-4223
                      </Typography>

                      <Typography
                        sx={{ fontSize: '12px !important', color: '#fff !important', fontWeight: '600 !important', cursor: 'pointer' }}
                        className='navbar_cal_but2'
                        onClick={() => (window.location.href = 'sms:404-590-4570')}
                      >
                        Text 404-590-4570
                      </Typography>
                    </Box>




                  </Grid>
                </Grid>

              </Grid>

              <Grid className='head_top_2'>

                <Grid>
                  <Grid className='head_top_2_nav'>

                    <Box sx={styles.menuListPadding}>

                      <nav style={{ display: 'flex', flex: '5.5' }}>
                        <List sx={styles.menuList}>

                          <ListItem disablePadding>
                            <>
                              <DropdownContext.Provider value={dropdownContextValue1}>

                                <MenuButton>

                                  <Stack
                                    direction={'row'}
                                    alignItems={'center'}
                                    spacing={1}
                                    sx={{
                                      position: 'relative',
                                      '&::after': {
                                        content: '""',
                                        position: 'absolute',
                                        bottom: 0,
                                        left: 0,
                                        width: 0,
                                        height: '2px', // Adjust thickness as needed
                                        backgroundColor: 'primary.main', // MUI primary color
                                        transition: 'width 0.3s ease', // Transition effect
                                      },
                                      '&:hover::after': {
                                        width: '100%',
                                      }
                                    }}
                                  >
                                    <Typography
                                      variant='h6'
                                      sx={{
                                        fontSize: { xs: "9px !important", md: "9px !important", lg: "12px !important" },
                                        width: { xs: '90px', lg: 'auto' }
                                      }}
                                    >
                                      DRIVING LESSONS/DRIVERS ED
                                    </Typography>
                                    <IoIosArrowDown size={15} />
                                  </Stack>

                                </MenuButton>


                                <Menu id="hooks-menu" >
                                  <MenuItem>

                                    <Typography
                                      variant="caption"
                                      className="menu-item"
                                      onClick={() =>
                                        navigate('/schedule-driving-lessons-2', {
                                          state: { number: 1 },
                                        })
                                      }
                                      sx={{
                                        cursor: 'pointer',
                                        padding: '8px 16px',
                                        borderRadius: '4px',
                                        transition: 'background-color 0.3s, color 0.3s',
                                        '&:hover': {
                                          backgroundColor: '#1976d2', // MUI primary blue for hover background
                                          color: '#ffffff !important', // White text color
                                          '.menu-item-hover': { color: '#ffffff !important' },
                                        },
                                      }}
                                    >
                                      Driving Lesson
                                    </Typography>




                                    <Typography variant='caption' className="menu-item" sx={{
                                      cursor: 'pointer',
                                      padding: '8px 16px',
                                      borderRadius: '4px',
                                      transition: 'background-color 0.3s, color 0.3s',
                                      '&:hover': {
                                        backgroundColor: '#1976d2', // MUI primary blue for hover background
                                        color: '#ffffff !important', // White text color
                                        '.menu-item-hover': { color: '#ffffff !important' },
                                      },
                                    }} onClick={() => navigate('/drivers-education')}>
                                      Driver’s Ed - Joshua‘s law
                                    </Typography>

                                    <Typography variant='caption' className="menu-item" sx={{
                                      cursor: 'pointer',
                                      padding: '8px 16px',
                                      borderRadius: '4px',
                                      transition: 'background-color 0.3s, color 0.3s',
                                      '&:hover': {
                                        backgroundColor: '#1976d2', // MUI primary blue for hover background
                                        color: '#ffffff !important', // White text color
                                        '.menu-item-hover': { color: '#ffffff !important' },
                                      },
                                    }} onClick={() => navigate('/online-drivers-education')}>
                                      Online - Self Paced Driver’s Ed
                                    </Typography>

                                    <Typography variant='caption' className="menu-item" sx={{
                                      cursor: 'pointer',
                                      padding: '8px 16px',
                                      borderRadius: '4px',
                                      transition: 'background-color 0.3s, color 0.3s',
                                      '&:hover': {
                                        backgroundColor: '#1976d2', // MUI primary blue for hover background
                                        color: '#ffffff !important', // White text color
                                        '.menu-item-hover': { color: '#ffffff !important' },
                                      },
                                    }} onClick={() => navigate('/gdec-scholarship')}>
                                      GDEC Scholarship
                                    </Typography>
                                  </MenuItem>

                                </Menu>
                              </DropdownContext.Provider>

                              <Styles />
                            </>
                          </ListItem>

                          <ListItem disablePadding>
                            <Typography
                              component='a'
                              onClick={() => navigate('/road-test-services')}
                              style={{ cursor: 'pointer' }}
                              sx={{
                                position: 'relative',
                                '&::after': {
                                  content: '""',
                                  position: 'absolute',
                                  bottom: 0,
                                  left: 0,
                                  width: 0,
                                  height: '2px', // Adjust thickness as needed
                                  backgroundColor: 'primary.main', // MUI primary color
                                  transition: 'width 0.3s ease', // Transition effect
                                },
                                '&:hover::after': {
                                  width: '100%',
                                }
                              }}
                            >
                              ROAD TESTING
                            </Typography>
                          </ListItem>

                          <ListItem disablePadding>
                            <>
                              <DropdownContext.Provider value={dropdownContextValue2}>
                                <MenuButton >
                                  <Stack
                                    direction={'row'}
                                    alignItems={'center'}
                                    spacing={1}
                                    sx={{
                                      position: 'relative',
                                      '&::after': {
                                        content: '""',
                                        position: 'absolute',
                                        bottom: 0,
                                        left: 0,
                                        width: 0,
                                        height: '2px', // Adjust thickness as needed
                                        backgroundColor: 'primary.main', // MUI primary color
                                        transition: 'width 0.3s ease', // Transition effect
                                      },
                                      '&:hover::after': {
                                        width: '100%',
                                      }
                                    }}
                                  >
                                    <Typography variant='h6' sx={{ fontSize: { xs: "9px !important", md: "9px !important", lg: "12px !important" }, width: { xs: '90px', lg: 'auto' } }}>DEFENSIVE DRIVING</Typography>
                                    <IoIosArrowDown size={15} />
                                  </Stack>
                                </MenuButton>
                                <Menu id="defensive_driving">
                                  <MenuItem>

                                    <Typography
                                      variant="h6"
                                      sx={{
                                        mb: 2, textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)', textAlign: 'center', color: '#737373 ', fontWeight: 'bold', textDecoration: 'none', cursor: 'default',
                                        animation: 'shadowBlink 2s infinite ease-in-out', // Blinking animation
                                        '@keyframes shadowBlink': {
                                          '0%': { textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)' },  // Normal shadow
                                          '50%': {
                                            textShadow: '2px 2px 8px rgb(38 177 112)' // Red-tinted shadow
                                          }, // Stronger red shadow in the middle
                                          '100%': { textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)' },  // Back to normal shadow
                                        }
                                      }}
                                    >
                                      DDS Certified / Court Approved
                                    </Typography>

                                    <Typography component='a' variant='caption' sx={{
                                      cursor: 'pointer',
                                      padding: '8px 16px',
                                      borderRadius: '4px',
                                      transition: 'background-color 0.3s, color 0.3s',
                                      '&:hover': {
                                        backgroundColor: '#1976d2', // MUI primary blue for hover background
                                        color: '#ffffff !important', // White text color
                                        '.menu-item-hover': { color: '#ffffff !important' },
                                      },
                                    }} className="menu-item" onClick={() => navigate('/defensive-driving-course-remote', {
                                      state: { number: 1 }
                                    })
                                    }
                                    >
                                      Defensive Driving Remote (Zoom)
                                    </Typography>

                                    <Typography component='a' variant='caption' sx={{
                                      cursor: 'pointer',
                                      padding: '8px 16px',
                                      borderRadius: '4px',
                                      transition: 'background-color 0.3s, color 0.3s',
                                      '&:hover': {
                                        backgroundColor: '#1976d2', // MUI primary blue for hover background
                                        color: '#ffffff !important', // White text color
                                        '.menu-item-hover': { color: '#ffffff !important' },
                                      },
                                    }} className="menu-item" onClick={() => navigate('/defensive-driving-at-location')}>
                                      Defensive Driving at Location (In Person)
                                    </Typography>

                                  </MenuItem>
                                </Menu>
                              </DropdownContext.Provider>
                              <Styles />
                            </>
                          </ListItem>

                          <ListItem disablePadding>
                            <>
                              <DropdownContext.Provider value={dropdownContextValue3}>
                                <MenuButton>
                                  <Stack direction={'row'} alignItems={'center'} spacing={1} sx={{
                                    position: 'relative',
                                    '&::after': {
                                      content: '""',
                                      position: 'absolute',
                                      bottom: 0,
                                      left: 0,
                                      width: 0,
                                      height: '2px', // Adjust thickness as needed
                                      backgroundColor: 'primary.main', // MUI primary color
                                      transition: 'width 0.3s ease', // Transition effect
                                    },
                                    '&:hover::after': {
                                      width: '100%',
                                    }
                                  }}>
                                    <Typography variant='h6' sx={{
                                      fontSize: { xs: "9px !important", md: "9px !important", lg: "12px !important", position: 'relative', },
                                      width: { xs: '90px', lg: 'auto' }
                                    }}>DUI/RISK REDUCTION</Typography>
                                    <IoIosArrowDown size={15} />
                                  </Stack>
                                </MenuButton>
                                <Menu id="risk_reduction">
                                  <MenuItem>

                                    <Typography
                                      variant="h6"
                                      sx={{
                                        mb: 2, textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)', textAlign: 'center', color: '#737373 ', fontWeight: 'bold', textDecoration: 'none', cursor: 'default',
                                        animation: 'shadowBlink 2s infinite ease-in-out', // Blinking animation
                                        '@keyframes shadowBlink': {
                                          '0%': { textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)' },  // Normal shadow
                                          '50%': {
                                            textShadow: '2px 2px 8px rgb(38 177 112)' // Red-tinted shadow
                                          }, // Stronger red shadow in the middle
                                          '100%': { textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)' },  // Back to normal shadow
                                        }
                                      }}
                                    >
                                      DDS Certified / Court Approved
                                    </Typography>

                                    <Typography variant='caption' className="menu-item" sx={{
                                      cursor: 'pointer',
                                      padding: '8px 16px',
                                      borderRadius: '4px',
                                      transition: 'background-color 0.3s, color 0.3s',
                                      '&:hover': {
                                        backgroundColor: '#1976d2', // MUI primary blue for hover background
                                        color: '#ffffff !important', // White text color
                                        '.menu-item-hover': { color: '#ffffff !important' },
                                      },
                                    }} onClick={() => navigate('/dui-risk-reduction-course-remote', {
                                      state: { number: 1 }
                                    })
                                    }
                                    >
                                      DUI/ Risk Reduction Remote (Zoom)
                                    </Typography>

                                    <Typography variant='caption' className="menu-item" sx={{
                                      cursor: 'pointer',
                                      padding: '8px 16px',
                                      borderRadius: '4px',
                                      transition: 'background-color 0.3s, color 0.3s',
                                      '&:hover': {
                                        backgroundColor: '#1976d2', // MUI primary blue for hover background
                                        color: '#ffffff !important', // White text color
                                        '.menu-item-hover': { color: '#ffffff !important' },
                                      },
                                    }} onClick={() => navigate('/dui-risk-reduction-at-location')}>
                                      DUI/Risk Reduction At Location
                                    </Typography>

                                  </MenuItem>
                                </Menu>
                              </DropdownContext.Provider>
                              <Styles />
                            </>
                          </ListItem>

                          <ListItem disablePadding>
                            <Typography sx={{
                              cursor: 'pointer', position: 'relative',
                              '&::after': {
                                content: '""',
                                position: 'absolute',
                                bottom: 0,
                                left: 0,
                                width: 0,
                                height: '2px', // Adjust thickness as needed
                                backgroundColor: 'primary.main', // MUI primary color
                                transition: 'width 0.3s ease', // Transition effect
                              },
                              '&:hover::after': {
                                width: '100%',
                              }
                            }} component='a' onClick={() => navigate('/clinical-evaluation')}>
                              CLINICAL EVALUATION
                            </Typography>
                          </ListItem>


                          <ListItem disablePadding>
                            <Typography sx={{
                              cursor: 'pointer', position: 'relative',
                              '&::after': {
                                content: '""',
                                position: 'absolute',
                                bottom: 0,
                                left: 0,
                                width: 0,
                                height: '2px', // Adjust thickness as needed
                                backgroundColor: 'primary.main', // MUI primary color
                                transition: 'width 0.3s ease', // Transition effect
                              },
                              '&:hover::after': {
                                width: '100%',
                              }
                            }} component='a' onClick={() => navigate('/victim-impact-panel')}>
                              VICTIM IMPACT
                            </Typography>
                          </ListItem>

                          <ListItem disablePadding>
                            <Typography component='a' sx={{
                              cursor: 'pointer', marginRight: { xs: '10px', md: '10px', lg: 'none' }, position: 'relative',
                              '&::after': {
                                content: '""',
                                position: 'absolute',
                                bottom: 0,
                                left: 0,
                                width: 0,
                                height: '2px', // Adjust thickness as needed
                                backgroundColor: 'primary.main', // MUI primary color
                                transition: 'width 0.3s ease', // Transition effect
                              },
                              '&:hover::after': {
                                width: '100%',
                              }
                            }} onClick={() => navigate('/our-locations')}>
                              LOCATIONS
                            </Typography>
                          </ListItem>



                        </List>

                      </nav>

                      <nav className='nav2' style={{ display: 'flex', flex: '0.9', backgroundColor: 'white !important' }}>
                        <List sx={{ display: 'flex', gap: '36px', padding: '0px !important', }}>

                          {

                            (userid) ?

                              < ListItem disablePadding>

                                <Stack direction={'row'} spacing={2} alignItems={'center'} sx={{ cursor: 'pointer' }}
                                  onClick={handleClick}
                                >
                                  {/* <Avatar sx={{ bgcolor: 'orange', width: 30, height: 30 }} src={user} /> */}
                                  <Avatar sx={{ bgcolor: 'orange', width: 30, height: 30 }}>
                                    {studentDetails?.customername?.[0]?.toUpperCase()}
                                  </Avatar>

                                  <Typography sx={{ color: 'white !important' }}>
                                    {studentDetails?.customername}
                                  </Typography>

                                </Stack>

                              </ListItem>

                              : (
                                <ListItem disablePadding sx={{ ml: 3 }}>
                                  <Button onClick={() => setRegisterStatus(true)} variant="contained" sx={styles.loginButton} >
                                    LOGIN
                                  </Button>
                                </ListItem>
                              )

                          }

                        </List>
                      </nav>



                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>


        </AppBar>

      </Box >
      {/* desktop */}


      {/* tablet view */}
      <Box sx={styles.tabletView}>

        <Stack direction={'column'}>

          <Stack direction={'row'} justifyContent={'space-between'}>

            <Box onClick={() => navigate('/')} sx={{ width: '10%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img src={logo} alt='1ACT-Logo' style={{ width: "80px", height: "40px" }} />
            </Box>

            <Stack direction={'row'} justifyContent={'space-evenly'} alignItems={'center'} sx={{ background: '#1976d2', width: '80%' }} >

              <Typography variant='caption' sx={{ color: '#f8fafc' }}>Mon-Fri (8am-8pm) / Sat & Sun (8am-6pm)</Typography>

              <Stack alignItems={'center'} direction={'row'} >

                <Typography
                  onClick={() => (window.location.href = 'mailto:info@1actdrivingschools.com')}
                  variant='caption'
                  style={{ cursor: 'pointer', color: '#f8fafc' }}
                >
                  info@1actdrivingschools.com
                </Typography>

                <Box sx={{ display: 'flex' }}>
                  <Typography
                    sx={{ fontSize: '12px !important', color: '#fff !important', fontWeight: '600 !important', cursor: 'pointer' }}
                    className='navbar_cal_but2'
                    onClick={() => (window.location.href = 'tel:770-274-4223')}
                  >
                    Call 770-274-4223
                  </Typography>

                  <Typography
                    sx={{ fontSize: '12px !important', color: '#fff !important', fontWeight: '600 !important', cursor: 'pointer' }}
                    className='navbar_cal_but2'
                    onClick={() => (window.location.href = 'sms:404-590-4570')}
                  >
                    Text 404-590-4570
                  </Typography>
                </Box>

              </Stack>

            </Stack>

            <Box sx={{ width: '10%', background: 'black' }}>

              {

                (userid) ?

                  <>

                    <Stack direction={'row'} spacing={2} alignItems={'center'} sx={{ cursor: 'pointer', padding: '5px 10px' }}
                      onClick={handleClick}
                    >
                      {/* <Avatar sx={{ bgcolor: 'orange', width: 30, height: 30 }} src={user} /> */}
                      <Avatar sx={{ bgcolor: 'orange', width: 30, height: 30 }}>
                        {studentDetails?.customername?.[0]?.toUpperCase()}
                      </Avatar>

                      <Typography variant='caption' sx={{ color: 'white !important' }}>
                        {studentDetails?.customername}
                      </Typography>

                    </Stack>

                  </>

                  : (
                    <>
                      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '5px 10px' }}>
                        <Button onClick={() => setRegisterStatus(true)} variant="contained" sx={styles.loginButton} >
                          LOGIN
                        </Button>
                      </Box>
                    </>
                  )

              }

            </Box>

          </Stack>

          <nav>
            <List sx={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingLeft: '20px' }}>

              <ListItem disablePadding sx={{ display: 'flex', justifyContent: 'center' }}>
                <>
                  <DropdownContext.Provider value={dropdownContextValue4}>

                    <MenuButton>

                      <Stack
                        direction={'row'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        spacing={1}
                        sx={{
                          position: 'relative',
                          '&::after': {
                            content: '""',
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            width: 0,
                            height: '2px', // Adjust thickness as needed
                            backgroundColor: 'primary.main', // MUI primary color
                            transition: 'width 0.3s ease', // Transition effect
                          },
                          '&:hover::after': {
                            width: '100%',
                          }
                        }}
                      >
                        <Typography
                          variant='h6'
                          sx={{
                            fontSize: '8px !important',
                            // width: { xs: '90px', lg: 'auto' }
                          }}
                        >
                          DRIVING LESSONS/DRIVERS ED
                        </Typography>
                        <IoIosArrowDown size={12} />
                      </Stack>

                    </MenuButton>


                    <Menu id="hooks-menu" >
                      <MenuItem>

                        <Typography
                          variant="caption"
                          className="menu-item"
                          onClick={() =>
                            navigate('/schedule-driving-lessons-2', {
                              state: { number: 1 },
                            })
                          }
                          sx={{
                            cursor: 'pointer',
                            padding: '8px 16px',
                            borderRadius: '4px',
                            transition: 'background-color 0.3s, color 0.3s',
                            '&:hover': {
                              backgroundColor: '#1976d2', // MUI primary blue for hover background
                              color: '#ffffff !important', // White text color
                              '.menu-item-hover': { color: '#ffffff !important' },
                            },
                          }}
                        >
                          Driving Lesson
                        </Typography>




                        <Typography variant='caption' className="menu-item" sx={{
                          cursor: 'pointer',
                          padding: '8px 16px',
                          borderRadius: '4px',
                          transition: 'background-color 0.3s, color 0.3s',
                          '&:hover': {
                            backgroundColor: '#1976d2', // MUI primary blue for hover background
                            color: '#ffffff !important', // White text color
                            '.menu-item-hover': { color: '#ffffff !important' },
                          },
                        }} onClick={() => navigate('/drivers-education')}>
                          Driver’s Ed - Joshua‘s law
                        </Typography>

                        <Typography variant='caption' className="menu-item" sx={{
                          cursor: 'pointer',
                          padding: '8px 16px',
                          borderRadius: '4px',
                          transition: 'background-color 0.3s, color 0.3s',
                          '&:hover': {
                            backgroundColor: '#1976d2', // MUI primary blue for hover background
                            color: '#ffffff !important', // White text color
                            '.menu-item-hover': { color: '#ffffff !important' },
                          },
                        }} onClick={() => navigate('/online-drivers-education')}>
                          Online - Self Paced Driver’s Ed
                        </Typography>

                        <Typography variant='caption' className="menu-item" sx={{
                          cursor: 'pointer',
                          padding: '8px 16px',
                          borderRadius: '4px',
                          transition: 'background-color 0.3s, color 0.3s',
                          '&:hover': {
                            backgroundColor: '#1976d2', // MUI primary blue for hover background
                            color: '#ffffff !important', // White text color
                            '.menu-item-hover': { color: '#ffffff !important' },
                          },
                        }} onClick={() => navigate('/gdec-scholarship')}>
                          GDEC Scholarship
                        </Typography>
                      </MenuItem>

                    </Menu>
                  </DropdownContext.Provider>

                  <Styles />
                </>
              </ListItem>

              <ListItem disablePadding sx={{ display: 'flex', justifyContent: 'center' }}>
                <Typography
                  component='h6'
                  onClick={() => navigate('/road-test-services')}
                  sx={{
                    position: 'relative',
                    cursor: 'pointer',
                    fontSize: '8px !important',
                    '&::after': {
                      content: '""',
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      width: 0,
                      height: '2px', // Adjust thickness as needed
                      backgroundColor: 'primary.main', // MUI primary color
                      transition: 'width 0.3s ease', // Transition effect
                    },
                    '&:hover::after': {
                      width: '100%',
                    }
                  }}
                >
                  ROAD TESTING
                </Typography>
              </ListItem>

              <ListItem disablePadding sx={{ display: 'flex', justifyContent: 'center' }}>
                <>
                  <DropdownContext.Provider value={dropdownContextValue5}>
                    <MenuButton >
                      <Stack
                        direction={'row'}
                        alignItems={'center'}
                        spacing={1}
                        sx={{
                          position: 'relative',
                          '&::after': {
                            content: '""',
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            width: 0,
                            height: '2px', // Adjust thickness as needed
                            backgroundColor: 'primary.main', // MUI primary color
                            transition: 'width 0.3s ease', // Transition effect
                          },
                          '&:hover::after': {
                            width: '100%',
                          }
                        }}
                      >
                        <Typography variant='h6' sx={{ fontSize: '8px !important' }}>DEFENSIVE DRIVING</Typography>
                        <IoIosArrowDown size={12} />
                      </Stack>
                    </MenuButton>
                    <Menu id="defensive_driving">
                      <MenuItem>

                        <Typography
                          variant="h6"
                          sx={{
                            mb: 2, textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)', textAlign: 'center', color: '#737373 ', fontWeight: 'bold', textDecoration: 'none', cursor: 'default',
                            animation: 'shadowBlink 2s infinite ease-in-out', // Blinking animation
                            '@keyframes shadowBlink': {
                              '0%': { textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)' },  // Normal shadow
                              '50%': {
                                textShadow: '2px 2px 8px rgb(38 177 112)' // Red-tinted shadow
                              }, // Stronger red shadow in the middle
                              '100%': { textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)' },  // Back to normal shadow
                            }
                          }}
                        >
                          DDS Certified / Court Approved
                        </Typography>

                        <Typography component='a' variant='caption' sx={{
                          cursor: 'pointer',
                          padding: '8px 16px',
                          borderRadius: '4px',
                          transition: 'background-color 0.3s, color 0.3s',
                          '&:hover': {
                            backgroundColor: '#1976d2', // MUI primary blue for hover background
                            color: '#ffffff !important', // White text color
                            '.menu-item-hover': { color: '#ffffff !important' },
                          },
                        }} className="menu-item" onClick={() => navigate('/defensive-driving-course-remote', {
                          state: { number: 1 }
                        })
                        }
                        >
                          Defensive Driving Remote (Zoom)
                        </Typography>

                        <Typography component='a' variant='caption' sx={{
                          cursor: 'pointer',
                          padding: '8px 16px',
                          borderRadius: '4px',
                          transition: 'background-color 0.3s, color 0.3s',
                          '&:hover': {
                            backgroundColor: '#1976d2', // MUI primary blue for hover background
                            color: '#ffffff !important', // White text color
                            '.menu-item-hover': { color: '#ffffff !important' },
                          },
                        }} className="menu-item" onClick={() => navigate('/defensive-driving-at-location')}>
                          Defensive Driving at Location (In Person)
                        </Typography>

                      </MenuItem>
                    </Menu>
                  </DropdownContext.Provider>
                  <Styles />
                </>
              </ListItem>

              <ListItem disablePadding sx={{ display: 'flex', justifyContent: 'center' }}>
                <>
                  <DropdownContext.Provider value={dropdownContextValue6}>
                    <MenuButton>
                      <Stack direction={'row'} alignItems={'center'} spacing={1} sx={{
                        position: 'relative',
                        '&::after': {
                          content: '""',
                          position: 'absolute',
                          bottom: 0,
                          left: 0,
                          width: 0,
                          height: '2px', // Adjust thickness as needed
                          backgroundColor: 'primary.main', // MUI primary color
                          transition: 'width 0.3s ease', // Transition effect
                        },
                        '&:hover::after': {
                          width: '100%',
                        }
                      }}>
                        <Typography variant='h6' sx={{
                          fontSize: '8px !important',
                          // width: { xs: '90px', lg: 'auto' }
                        }}>DUI/RISK REDUCTION</Typography>
                        <IoIosArrowDown size={15} />
                      </Stack>
                    </MenuButton>
                    <Menu id="risk_reduction">
                      <MenuItem>

                        <Typography
                          variant="h6"
                          sx={{
                            mb: 2, textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)', textAlign: 'center', color: '#737373 ', fontWeight: 'bold', textDecoration: 'none', cursor: 'default',
                            animation: 'shadowBlink 2s infinite ease-in-out', // Blinking animation
                            '@keyframes shadowBlink': {
                              '0%': { textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)' },  // Normal shadow
                              '50%': {
                                textShadow: '2px 2px 8px rgb(38 177 112)' // Red-tinted shadow
                              }, // Stronger red shadow in the middle
                              '100%': { textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)' },  // Back to normal shadow
                            }
                          }}
                        >
                          DDS Certified / Court Approved
                        </Typography>

                        <Typography variant='caption' className="menu-item" sx={{
                          cursor: 'pointer',
                          padding: '8px 16px',
                          borderRadius: '4px',
                          transition: 'background-color 0.3s, color 0.3s',
                          '&:hover': {
                            backgroundColor: '#1976d2', // MUI primary blue for hover background
                            color: '#ffffff !important', // White text color
                            '.menu-item-hover': { color: '#ffffff !important' },
                          },
                        }} onClick={() => navigate('/dui-risk-reduction-course-remote', {
                          state: { number: 1 }
                        })
                        }
                        >
                          DUI/ Risk Reduction Remote (Zoom)
                        </Typography>

                        <Typography variant='caption' className="menu-item" sx={{
                          cursor: 'pointer',
                          padding: '8px 16px',
                          borderRadius: '4px',
                          transition: 'background-color 0.3s, color 0.3s',
                          '&:hover': {
                            backgroundColor: '#1976d2', // MUI primary blue for hover background
                            color: '#ffffff !important', // White text color
                            '.menu-item-hover': { color: '#ffffff !important' },
                          },
                        }} onClick={() => navigate('/dui-risk-reduction-at-location')}>
                          DUI/Risk Reduction At Location
                        </Typography>

                      </MenuItem>
                    </Menu>
                  </DropdownContext.Provider>
                  <Styles />
                </>
              </ListItem>

              <ListItem disablePadding sx={{ display: 'flex', justifyContent: 'center' }}>
                <Typography
                  variant='h6'
                  sx={{
                    cursor: 'pointer',
                    color: 'black',
                    fontSize: '8px !important',
                    position: 'relative',
                    '&::after': {
                      content: '""',
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      width: 0,
                      height: '2px', // Adjust thickness as needed
                      backgroundColor: 'primary.main', // MUI primary color
                      transition: 'width 0.3s ease', // Transition effect
                    },
                    '&:hover::after': {
                      width: '100%',
                    }
                  }} onClick={() => navigate('/clinical-evaluation')}>
                  CLINICAL EVALUATION
                </Typography>
              </ListItem>


              <ListItem disablePadding sx={{ display: 'flex', justifyContent: 'center' }}>
                <Typography
                  variant='h6'
                  sx={{
                    cursor: 'pointer',
                    position: 'relative',
                    fontSize: '8px !important',
                    '&::after': {
                      content: '""',
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      width: 0,
                      height: '2px', // Adjust thickness as needed
                      backgroundColor: 'primary.main', // MUI primary color
                      transition: 'width 0.3s ease', // Transition effect
                    },
                    '&:hover::after': {
                      width: '100%',
                    }
                  }} onClick={() => navigate('/victim-impact-panel')}>
                  VICTIM IMPACT
                </Typography>
              </ListItem>

              <ListItem disablePadding sx={{ display: 'flex', justifyContent: 'center' }}>
                <Typography variant='h6' sx={{
                  cursor: 'pointer',
                  position: 'relative',
                  fontSize: '8px !important',
                  '&::after': {
                    content: '""',
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    width: 0,
                    height: '2px', // Adjust thickness as needed
                    backgroundColor: 'primary.main', // MUI primary color
                    transition: 'width 0.3s ease', // Transition effect
                  },
                  '&:hover::after': {
                    width: '100%',
                  }
                }} onClick={() => navigate('/our-locations')}>
                  LOCATIONS
                </Typography>
              </ListItem>

            </List>

          </nav>


        </Stack>

      </Box >
      {/* tablet view */}


      < ExistingLogin
        openStatus={openStatus}
        setRegisterStatus={setRegisterStatus}
      />


      <Popover
        id={id}
        open={openState}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={{ top: 98, left: 1300 }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}

      >

        {/* <AddtoCart /> */}


        <div style={{ padding: '10px', width: '220px' }}>
          <Stack direction={'row'} spacing={2} alignItems={'center'} sx={{ cursor: 'pointer' }} onClick={() => navigate('/myprofile')}>
            <FaRegUserCircle style={{ color: '#0b3863' }} />
            <Typography>My profile</Typography>
          </Stack>
          <Divider sx={{ my: 1 }} />

          {/* <Stack direction={'row'} spacing={2} alignItems={'center'} sx={{ cursor: 'pointer' }}>
            <CiWallet  style={{ color: '#0b3863' }}/>
            <Typography>Orders</Typography>
          </Stack> */}

          {/* <Divider sx={{ my: 1 }} /> */}

          {/* <a href='https://user.projectsideaboxdigital.io.in' target='_blank'  style={{ textDecoration: 'none' }}> */}
          <Stack direction={'row'} spacing={2} alignItems={'center'} sx={{ cursor: 'pointer' }} onClick={handleGoToDashboard}>
            <AiOutlineDashboard style={{ color: '#0b3863' }} />
            <Typography>Go to dashboard</Typography>
          </Stack>
          {/* </a> */}

          <Divider sx={{ my: 1 }} />

          <Stack direction={'row'} spacing={2} alignItems={'center'} sx={{ cursor: 'pointer' }} onClick={() =>
            handleLogout()

          }
          >
            <IoIosLogOut style={{ color: '#0b3863' }} />
            <Typography>Logout</Typography>
          </Stack>

        </div>





      </Popover >


      {/********mobileHeader*********/}

      < Box sx={styles.mobileCanvas} >

        <Grid>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} sx={{ padding: '10px' }}>
            <Typography component='a' href='/'>
              <CardMedia className="" component="img" alt="logo" src={logo} sx={{ width: '140px', }} />
            </Typography>

            <Button variant="contained" onClick={toggleDrawer(true)} sx={{ height: '46px', }}>
              Menu <DehazeIcon style={{ margin: '0 0 0 7px' }} />
            </Button>
          </Stack>

          {/* <Box sx={{ display: 'flex',justifyContent:'flex-end',mb:1 }}>
            <Typography
              sx={{ fontSize: '12px !important', color: '#fff !important', fontWeight: '600 !important', cursor: 'pointer' }}
              className='navbar_cal_but2'
              onClick={() => (window.location.href = 'tel:770-274-4223')}
            >
              Call 770-274-4223
            </Typography>

            <Typography
              sx={{ fontSize: '12px !important', color: '#fff !important', fontWeight: '600 !important', cursor: 'pointer' }}
              className='navbar_cal_but2'
              onClick={() => (window.location.href = 'sms:404-590-4570')}
            >
              Text 404-590-4570
            </Typography>
          </Box> */}


        </Grid>

        <Drawer anchor="left" open={open} onClose={toggleDrawer(false)}
          ModalProps={{ BackdropProps: { invisible: true, }, }}
        >
          <Grid sx={{ width: '350px', padding: '10px', }}>

            <Grid className='mobileCanvasInHeader' sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #1872cb', pb: '20px', }}>
              <Typography component='a' href='/'>
                <CardMedia className="" component="img" alt="logo" src={logo} />
              </Typography>
              <Button variant="outlined" onClick={toggleDrawer(false)} sx={{ height: '44px', }}><CloseIcon /></Button>
            </Grid>

            {/********mobileContent*********/}

            <Grid className='mobileCanvasInContent' sx={{ mt: '18px' }}>

              <Accordion sx={styles.accordion}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header" sx={styles.AccordionSummary}>
                  <Typography component="f14" className="menuItemMobBut">
                    DRIVING LESSONS/DRIVERS ED
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="menuItemMob" onClick={() => {
                    navigate('/schedule-driving-lessons-2', {
                      state: { number: 1 }
                    })
                  }}>
                    Driving Lesson
                  </Typography>

                  <Typography className="menuItemMob" component="a" href="/drivers-education">
                    Driver’s Ed - joshua‘s law
                  </Typography>

                  <Typography className="menuItemMob" component="a" href="/online-drivers-education">
                    Online - Self Paced Driver’s Ed
                  </Typography>

                  <Typography component="a" className="menuItemMob" href='/gdec-scholarship'>
                    GDEC Scholarship
                  </Typography>

                </AccordionDetails>
              </Accordion>

              <Typography className="menuItemMob" component="a" href="/road-test-services"> ROAD TESTING </Typography>

              <Accordion sx={styles.accordion} className='accordion'>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header" sx={styles.AccordionSummary}>
                  <Typography component="f14" className="menuItemMobBut">
                    DEFENSIVE DRIVING
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>

                  <Typography
                    variant="h6"
                    sx={{
                      mb: 2, textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)', textAlign: 'center', color: '#737373 ', fontWeight: 'bold', textDecoration: 'none', cursor: 'default',
                      animation: 'shadowBlink 2s infinite ease-in-out', // Blinking animation
                      '@keyframes shadowBlink': {
                        '0%': { textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)' },  // Normal shadow
                        '50%': {
                          textShadow: '2px 2px 8px rgb(38 177 112)' // Red-tinted shadow
                        }, // Stronger red shadow in the middle
                        '100%': { textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)' },  // Back to normal shadow
                      }
                    }}
                  >
                    DDS Certified / Court Approved
                  </Typography>

                  <Typography className="menuItemMob" onClick={() => navigate('/defensive-driving-course-remote', {
                    state: { number: 1 }
                  })}>
                    Defensive Driving Remote (Zoom)
                  </Typography>
                  <Typography className="menuItemMob" component="a" href="/defensive-driving-at-location">
                    Defensive Driving at Location (In Person)
                  </Typography>


                </AccordionDetails>
              </Accordion>

              <Accordion sx={styles.accordion}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header" sx={styles.AccordionSummary}>
                  <Typography component="f14" className="menuItemMobBut">
                    DUI/RISK REDUCTION
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>

                  <Typography
                    variant="h6"
                    sx={{
                      mb: 2, textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)', textAlign: 'center', color: '#737373 ', fontWeight: 'bold', textDecoration: 'none', cursor: 'default',
                      animation: 'shadowBlink 2s infinite ease-in-out', // Blinking animation
                      '@keyframes shadowBlink': {
                        '0%': { textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)' },  // Normal shadow
                        '50%': {
                          textShadow: '2px 2px 8px rgb(38 177 112)' // Red-tinted shadow
                        }, // Stronger red shadow in the middle
                        '100%': { textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)' },  // Back to normal shadow
                      }
                    }}
                  >
                    DDS Certified / Court Approved
                  </Typography>

                  <Typography className="menuItemMob" onClick={() => navigate('/dui-risk-reduction-course-remote', {
                    state: { number: 1 }
                  })}>
                    DUI/ Risk Reduction Remote (Zoom)
                  </Typography>
                  <Typography className="menuItemMob" component="a" href="/dui-risk-reduction-at-location">
                    DUI/Risk Reduction At Location
                  </Typography>


                </AccordionDetails>
              </Accordion>

              <Typography className="menuItemMob" component="a" href="/clinical-evaluation"> CLINICAL EVALUATION  </Typography>


              <Typography className="menuItemMob" component="a" href="/victim-impact-panel"> VICTIM IMPACT  </Typography>

              <Typography className="menuItemMob" component="a" href="/our-locations"> LOCATIONS  </Typography>

              {
                (userid) ? <Stack direction={'row'} spacing={2} alignItems={'center'} sx={{ cursor: 'pointer', margin: '20px 0 0 0', border: '1px solid grey', padding: '10px 20px', borderRadius: '5px' }}
                  onClick={handleClick}
                >
                  {/* <Avatar sx={{ bgcolor: 'orange', width: 30, height: 30 }} src={user} /> */}
                  <Avatar sx={{ bgcolor: 'orange', width: 30, height: 30 }}>
                    {studentDetails?.customername?.[0]?.toUpperCase()}
                  </Avatar>

                  <Typography sx={{ color: 'black !important' }}>
                    {studentDetails?.customername}
                  </Typography>

                </Stack> : <Button onClick={() => setRegisterStatus(true)} variant="contained" sx={{ width: '100%', margin: '10px 0 0 0' }}  >
                  LOGIN
                </Button>
              }


              <Box sx={{ display: 'flex', justifyContent: 'flex-end', my: 3 }}>
                <Typography
                  sx={{ fontSize: '12px !important', color: '#fff !important', fontWeight: '600 !important', cursor: 'pointer' }}
                  className='navbar_cal_but2'
                  onClick={() => (window.location.href = 'tel:770-274-4223')}
                >
                  Call 770-274-4223
                </Typography>

                <Typography
                  sx={{ fontSize: '12px !important', color: '#fff !important', fontWeight: '600 !important', cursor: 'pointer' }}
                  className='navbar_cal_but2'
                  onClick={() => (window.location.href = 'sms:404-590-4570')}
                >
                  Text 404-590-4570
                </Typography>
              </Box>




            </Grid>
          </Grid>

        </Drawer>

      </Box >

      {/********mobileHeader*********/}


    </>

  );
}

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

function useIsDarkMode() {
  const theme = useTheme();
  return theme.palette.mode === 'dark';
}

function Styles() {
  // Replace this with your app logic for determining dark mode
  const isDarkMode = useIsDarkMode();

  const styles = `

    .menu-root {
      font-size: 10px ;
      box-sizing: border-box;
      padding: 10px 4px;
      margin: 10px 0;
      background: #fff;
      border: 1px solid ${grey[200]};
      border-radius: 0.75em;
      color: ${grey[900]};
      overflow: auto;
      outline: 0px;
      box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.05);
    }

    .menu-item {
      list-style: none;
      padding: 10px 10px;
      font-size: 12px ;
      font-weight:500;
      cursor:pointer;
      display:block;
      color: #151515 !important;

      &:hover{
        color: #ffffff !important;
      }
    }

    .button {
      font-weight: 600;
      font-size: 12px;
      padding: 6px 0px;
      transition: all 150ms ease;
      cursor: pointer;
      background: none;
      border: none;
      color: ${isDarkMode ? grey[200] : grey[900]};
      box-shadow: none;
    }
  `;

  // eslint-disable-next-line react/no-danger
  return <style dangerouslySetInnerHTML={{ __html: styles }} />;
}


export default Header;